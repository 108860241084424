import * as INCIDENT_TYPES from "@/const/incident-types.js";

import paginationMixin from "./pagination-mixin.js";

export default {
  mixins: [paginationMixin],
  data() {
    return {
      filter: {
        title: "",
        registrationNumber: "",
        reportedAt: null,
        reportedBy: "",
        type: null,
        issueCategoryId: null,
        status: null,
        incidentResponsibleUser: "",
        responsibleUser: "",
        modifiedAt: null,
        modifiedBy: "",
        closedAt: null,
        closedBy: ""
      }
    };
  },
  computed: {
    incidents() {
      return this.$store.getters["incidents/incidents"];
    },
    issueCategories() {
      return this.$store.state.issueCategories.items;
    },
    incidentTypeOptions() {
      let result = [
        { value: { type: null }, text: "" },
        { value: { type: INCIDENT_TYPES.SALES_INCIDENT_TYPE }, text: this.$t("incident-type.sales") },
        { value: { type: INCIDENT_TYPES.REPAIR_INCIDENT_TYPE }, text: this.$t("incident-type.repair") },
        { value: { type: INCIDENT_TYPES.TRIM_LEVEL_INCIDENT_TYPE }, text: this.$t("incident-type.trim-level") }
      ];
      for (const category of this.issueCategories) {
        result.push({
          text: category.title[this.$i18n.locale],
          value: { type: INCIDENT_TYPES.CUSTOM_INCIDENT_TYPE, categoryId: category.id }
        });
      }
      return result;
    }
  },
  methods: {
    getSearchParams() {
      return {
        ...this.paginationParams,
        ...this.filter,
        reportedAt: this.filter.reportedAt ? this.filter.reportedAt.toISOString() : null,
        modifiedAt: this.filter.modifiedAt ? this.filter.modifiedAt.toISOString() : null,
        closedAt: this.filter.closedAt ? this.filter.closedAt.toISOString() : null
      };
    },
    // TODO unused method?
    onChangeFilterProperty(property, value) {
      if (this.filter.hasOwnProperty(property)) {
        this.filter[property] = value;
        return true;
      }

      return false;
    },
    setSort({ sortBy, sortDesc }) {
      this.sort.by = sortBy;
      this.sort.isDesc = sortDesc;
    }
  }
};
