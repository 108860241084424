<template>
  <b-container fluid class="overflowable">
    <b-row>
      <b-col>
        <incident-history />
        <edit-incident-modal />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IncidentHistory from "@/components/incident/IncidentHistory.vue";
import EditIncidentModal from "@/components/incident/EditIncidentModal.vue";

export default {
  components: {
    IncidentHistory,
    EditIncidentModal
  }
};
</script>
