<template>
  <div>
    <b-pagination
      :value="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :per-page="pagination.limit"
      @input="onChangeCurrentPage($event, loadIncidents)"
    ></b-pagination>
    <b-table
      :fields="fields"
      :items="incidents"
      :sort-desc.sync="sortDesc"
      :sort-by.sync="sortBy"
      responsive
      no-local-sorting
      @sort-changed="onTableSortChanged($event, loadIncidents)"
      :style="{ height: this.$store.availableHeightSpace }"
    >
      <template v-slot:top-row>
        <td></td>
        <td>
          <b-input
            :value="filter.title"
            @change="onChangeFilterValue(filter, 'title', $event, loadIncidents)"
          ></b-input>
        </td>
        <td>
          <b-input
            :value="filter.registrationNumber"
            @change="onChangeFilterValue(filter, 'registrationNumber', $event, loadIncidents)"
          ></b-input>
        </td>
        <td>
          <b-datepicker
            value-as-date
            reset-button
            :value="filter.reportedAt"
            :locale="$i18n.locale"
            :label-reset-button="$t('datepicker.reset-btn')"
            @input="onChangeFilterValue(filter, 'reportedAt', $event, loadIncidents)"
            placeholder=""
            label-no-date-selected=""
            boundary="window"
          ></b-datepicker>
        </td>
        <td>
          <b-input
            :value="filter.reportedBy"
            @change="onChangeFilterValue(filter, 'reportedBy', $event, loadIncidents)"
          ></b-input>
        </td>
        <td>
          <b-select
            :options="incidentTypeOptions"
            @input="onChangeFilterValue(filter, 'type', $event, loadIncidents)"
          ></b-select>
        </td>
        <td>
          <b-input
            :value="filter.incidentResponsibleUser"
            @change="onChangeFilterValue(filter, 'incidentResponsibleUser', $event, loadIncidents)"
          ></b-input>
        </td>
        <td>
          <b-datepicker
            value-as-date
            reset-button
            :value="filter.modifiedAt"
            :locale="$i18n.locale"
            :label-reset-button="$t('datepicker.reset-btn')"
            @input="onChangeFilterValue(filter, 'modifiedAt', $event, loadIncidents)"
            placeholder=""
            label-no-date-selected=""
            boundary="window"
          ></b-datepicker>
        </td>
        <td>
          <b-input
            :value="filter.modifiedBy"
            @change="onChangeFilterValue(filter, 'modifiedBy', $event, loadIncidents)"
          ></b-input>
        </td>
        <td>
          <b-input
            :value="filter.responsibleUser"
            @change="onChangeFilterValue(filter, 'responsibleUser', $event, loadIncidents)"
          ></b-input>
        </td>
        <td></td>
        <td>
          <b-datepicker
            value-as-date
            reset-button
            :value="filter.closedAt"
            :locale="$i18n.locale"
            :label-reset-button="$t('datepicker.reset-btn')"
            @input="onChangeFilterValue(filter, 'closedAt', $event, loadIncidents)"
            placeholder=""
            label-no-date-selected=""
            boundary="window"
          ></b-datepicker>
        </td>
        <td>
          <b-input
            :value="filter.closedBy"
            @change="onChangeFilterValue(filter, 'closedBy', $event, loadIncidents)"
          ></b-input>
        </td>
        <td></td>
      </template>
      <template v-slot:cell(isUrgent)="{ value }">
        <img v-if="value" src="/images/warning.png" class="mx-auto d-block" />
      </template>
      <template v-slot:cell(vehicleRegistrationNumber)="row">{{ row.item.vehicle.registrationNumber }}</template>
      <template v-slot:cell(reportedAt)="row">{{ $d(new Date(row.item.reportedAt), "log") }}</template>
      <template v-slot:cell(reportedBy)="row">
        {{ row.item.reportedBy.userName }}
        <phone-number :phoneNumber="row.item.reportedBy.phone"></phone-number>
      </template>
      <template v-slot:cell(type)="row">{{ translateType(row.item.type, row.item.issueCategoryId) }}</template>
      <template v-slot:cell(incidentResponsibleUser)="row">
        {{ row.item.incidentResponsibleUser == null ? "" : row.item.incidentResponsibleUser.userName }}
      </template>
      <template v-slot:cell(modifiedAt)="row">{{ $d(new Date(row.item.modifiedAt), "log") }}</template>
      <template v-slot:cell(modifiedBy)="row">
        {{ row.item.modifiedBy.userName }}
        <phone-number :phoneNumber="row.item.modifiedBy.phone"></phone-number>
      </template>
      <template v-slot:cell(responsibleUser)="row">
        {{ row.item.responsibleUser == null ? "" : row.item.responsibleUser.userName }}
      </template>
      <template v-slot:cell(files)="row"><img src="/images/check.svg" v-if="row.value.length > 0"/></template>
      <template v-slot:cell(closedAt)="row">{{ $d(new Date(row.item.modifiedAt), "log") }}</template>
      <template v-slot:cell(closedBy)="row">
        {{ row.item.modifiedBy.userName }}
        <phone-number :phoneNumber="row.item.modifiedBy.phone"></phone-number>
      </template>
      <template v-slot:cell(title)="row">
        <b-link @click="openIncident(row.item)">{{ row.item.title }}</b-link>
      </template>
    </b-table>
  </div>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

import { CUSTOM_INCIDENT_TYPE } from "@/const/incident-types.js";
import { UPDATE_INCIDENT_LIST } from "@/const/events.js";

import incidentTableMixin from "@/mixins/incident-table-mixin.js";

export default {
  mixins: [incidentTableMixin],
  components: {
    PhoneNumber
  },
  data() {
    return {
      sortDesc: false,
      sortBy: null,
      prevSortBy: null
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "isUrgent",
          label: this.$t("incident.is-urgent"),
          sortable: true
        },
        {
          key: "title",
          label: this.$t("incident.title"),
          sortable: true
        },
        {
          key: "vehicleRegistrationNumber",
          label: this.$t("incident.vehicle-registration-number"),
          sortable: true
        },
        { key: "reportedAt", label: this.$t("incident.reported-at"), sortable: true },
        { key: "reportedBy", label: this.$t("incident.reported-by"), sortable: true },
        { key: "type", label: this.$t("incident.type") },
        {
          key: "incidentResponsibleUser",
          label: this.$t("incident.incident-responsible-user"),
          sortable: true
        },
        { key: "modifiedAt", label: this.$t("incident.modified-at"), sortable: true },
        { key: "modifiedBy", label: this.$t("incident.modified-by"), sortable: true },
        {
          key: "responsibleUser",
          label: this.$t("incident.responsible-user"),
          sortable: true
        },
        { key: "files", label: this.$t("incident.files") },
        { key: "closedAt", label: this.$t("incident.closed-at"), sortable: true },
        { key: "closedBy", label: this.$t("incident.closed-by"), sortable: true }
      ];
    }
  },
  methods: {
    onTableSortChanged(event, loadDataCallback) {
      if (event.sortBy === "isUrgent" && this.prevSortBy !== event.sortBy) {
        event.sortDesc = true;
        this.sortDesc = true;
      }

      this.prevSortBy = event.sortBy;

      this.onSortChanged(event, loadDataCallback);
    },
    loadIncidents() {
      this.$store.dispatch("incidents/getClosedIncidents", this.getSearchParams()).then(resp => {
        this.$store.commit("incidents/setIncidentsState", resp.data.items);
        this.pagination.totalCount = resp.data.totalCount;
      });
    },
    openIncident(incident) {
      this.$router.push({ name: "incident", params: { incidentId: incident.id } });
    },
    translateType(type, issueCategoryId) {
      if (type == CUSTOM_INCIDENT_TYPE) {
        let issueCategory = this.incidentTypeOptions.find(x => x.value.categoryId == issueCategoryId);
        if (issueCategory) return issueCategory.text;
      } else {
        return this.$t(`incident-type.${type}`);
      }
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
    this.loadIncidents();
    this.$bus.on(UPDATE_INCIDENT_LIST, this.loadIncidents);
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_INCIDENT_LIST, this.loadIncidents);
  }
};
</script>
